/* @import "~ag-grid-community/src/styles/ag-grid.css";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin"; */

.ag-theme-balham .balham-active-color {
  color: #ff9900;
}

.ag-cell-inline-editing .ag-cell-edit-input,
.ag-cell-inline-editing .ag-input-wrapper {
  height: 100%;
  width: 100%;
  color: black;
  line-height: normal;
}
